<template>
<Navigation></Navigation>

  <!-- Accueil -->
<Header></Header>


  

  <!-- Intro -->
  <div id="intro" class="intro">
    <div class="section">
      <div class="container">
        <h2 class="title intro__title">Qui sommes-nous ?</h2>
        <div class="intro__left">
          <p class="text intro__text">
            La SRL Fiduciaire Monaux a été constituée en décembre 1975. Son
            membre fondateur Monsieur Robert Monaux a embrassé la profession de
            comptable-fiscaliste, en tant qu’entreprise individuelle, de février
            1956 à novembre 1975.<br /><br />Dominique et Pascal Monaux, ses
            deux fils, sont entrés en fonction au sein de la société,
            respectivement en avril 1979 et juillet 1983. Pour ensuite, en
            assurer seuls la direction à compter du 3 août 1988. Tous deux
            comptables de formation, ils portent chacun les titres
            d’expert-comptable certifié et de conseiller fiscal certifié. En
            mars 2014, entrée en fonction de la 3ème génération en la personne
            de Frédéric, bachelier en comptabilité depuis le 30 juin 2017.
          </p>
        </div>
        <!--
                 -->
        <div class="intro__right">
          <p class="text intro__text">
            Ils sont assistés dans leurs tâches quotidiennes par des
            collaborateurs compétents, dynamiques et expérimentés. Soucieux du
            rôle de confident et de conseiller privilégié attribué à la
            profession, chaque PME cliente se voit attribuer l’assistance
            personnalisée d’un collaborateur. Un seul interlocuteur donc, avec
            un visage sur un nom.<br /><br />Les bureaux sont situés à la
            périphérie sud de l’agglomération carolorégienne, dans le quartier
            résidentiel de la Bruyère, à Marcinelle. Accès et parking aisés.
            Consultations et horaires extensibles sur rendez-vous.
          </p>
          <p class="btn_container">
            <a href="mailto:info@fiduciaire-monaux.com" class="btn"
              >Contactez-nous</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Services -->
  <div id="services" sclass="services">
    <div class="section">
      <div class="container">
        <div class="services__intro">
          <h2 class="title services__title">Nos services</h2>
          <p class="text services__text">
            Les activités d'expert-comptable certifié consistent à exécuter dans
            les entreprises privées, les organismes publics ou pour compte de
            toute personne ou de tout organisme intéressé, les missions
            suivantes (article 3 de la loi du 17 mars 2019 relative aux
            profession d’expert-comptable et de conseiller fiscal) :
          </p>
          <h3 class="title title--small services__subtitle mobile_hide">
            Les activités de conseil fiscal certifié consistent à :
          </h3>
          <ul class="list services__intro_list mobile_hide">
            <li class="text list__item">
              Donner des avis se rapportant à toutes les matières fiscales.
            </li>
            <li class="text list__item">
              Assister les contribuables dans l'accomplissement de leurs
              obligations.
            </li>
            <li class="text list__item">Représenter les contribuables.</li>
          </ul>
          <p class="btn_container mobile_hide">
            <a href="mailto:info@fiduciaire-monaux.com" class="btn"
              >Contactez-nous</a
            >
          </p>
        </div>
        <!--
                 -->
        <div class="services__items">
          <ul class="services__list">
            <li class="services__item">
              <span class="services__icon services__icon--01"></span>
              <h3 class="title title--small services__name">Expertise</h3>
              <p class="text text--small services__desc">
                L'expertise, tant privée que judiciaire, dans le domaine de
                l'organisation comptable des entreprises ainsi que l'analyse par
                les procédés de la technique comptable de la situation et du
                fonctionnement des entreprises au point de vue de leur crédit,
                de leur rendement et de leurs risques.
              </p>
            </li>
            <!--
                         -->
            <li class="services__item">
              <span class="services__icon services__icon--02"></span>
              <h3 class="title title--small services__name">
                Missions spéciales
              </h3>
              <p class="text text--small services__desc">
                Les activités visées à l’article 6 § 1er, à l’exclusion de
                celles visées à l’article 6 § 1er 3°, pour les entreprises
                auprès desquelles l’expert-comptable certifié accomplit des
                missions visées à l’article 3, 7°.
              </p>
            </li>
            <!--
                         -->
            <li class="services__item">
              <span class="services__icon services__icon--03"></span>
              <h3 class="title title--small services__name">Organisation</h3>
              <p class="text text--small services__desc">
                L'organisation des services comptables et administratifs des
                entreprises et les activités de conseil en matière
                d'organisation comptable et administrative des entreprises.
              </p>
            </li>
            <!--
                         -->
            <li class="services__item">
              <span class="services__icon services__icon--04"></span>
              <h3 class="title title--small services__name">
                Missions diverses
              </h3>
              <p class="text text--small services__desc">
                Les autres missions dont l’accomplissement est réservé par la
                loi ou en vertu de la loi à l’expert-comptable certifié.
              </p>
            </li>
            <!--
                         -->
            <li class="services__item">
              <span class="services__icon services__icon--05"></span>
              <h3 class="title title--small services__name">
                Vérification et redressement
              </h3>
              <p class="text text--small services__desc">
                La vérification et le redressement de tous les documents
                comptables.
              </p>
            </li>
            <!--
                         -->
            <li class="services__item">
              <span class="services__icon services__icon--06"></span>
              <h3 class="title title--small services__name">
                Comptabilité de tiers
              </h3>
              <p class="text text--small services__desc">
                Les activités d'organisation et de tenue de la comptabilité de
                tiers.
              </p>
            </li>
          </ul>
          <h3 class="title title--small services__subtitle mobile_show">
            Les activités de conseil fiscal certifié consistent à :
          </h3>
          <ul class="list services__intro_list mobile_show">
            <li class="text list__item">
              Donner des avis se rapportant à toutes les matières fiscales.
            </li>
            <li class="text list__item">
              Assister les contribuables dans l'accomplissement de leurs
              obligations.
            </li>
            <li class="text list__item">Représenter les contribuables.</li>
          </ul>
          <p class="btn_container mobile_show">
            <a href="mailto:info@fiduciaire-monaux.com" class="btn"
              >Contactez-nous</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Separation -->
  <div
    class="separation skrollable skrollable-between"
    data-bottom-top="background-position: 50% 60%;"
    data-top-bottom="background-position: 50% 40%;"
    data-anchor-target=".separation"
    style="background-position: 50% 57.8621%"
  >
    <div
      class="separation__filter skrollable skrollable-between"
      data-bottom-top="opacity: .6;"
      data-top-bottom="opacity: 0;"
      data-anchor-target=".separation"
      style="opacity: 0.535862"
    ></div>
  </div>

  <!-- Utils -->
  <div id="utils" class="utils">
    <div class="section">
      <div class="container container--relative">
        <div class="utils__right">
          <div
            class="utils__copy skrollable skrollable-before"
            data-bottom-top="transform: translateY(-50px);"
            data-top-bottom="transform: translateY(50px);"
            data-anchor-target=".utils"
            style="transform: translateY(-50px)"
          >
            <h2 class="title utils__title">Liens utiles</h2>
            <ul class="list">
              <li class="list__item">
                <a
                  href="https://www.nbb.be/en/central-balance-sheet-office-0?l=fr"
                  target="_blank"
                  >Banque Nationale Belge</a
                >
              </li>
              <li class="list__item">
                <a href="https://finances.belgium.be/fr/" target="_blank"
                  >Service Public Fédéral FINANCES</a
                >
              </li>
              <li class="list__item">
                <a
                  href="https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html;jsessionid=CAF7230D394B20AE22272E4148DBED8C.worker4a?lang=fr"
                  target="_blank"
                  >Banque Carrefour des entreprises</a
                >
              </li>
              <li class="list__item">
                <a
                  href="http://www.ejustice.just.fgov.be/cgi_tsv/tsv.pl"
                  target="_blank"
                  >Publications Moniteur Belge</a
                >
              </li>
              <li class="list__item">
                <a
                  href="https://www.socialsecurity.be/site_fr/employer/applics/30bis/index.htm"
                  target="_blank"
                  >Obligations retenues</a
                >
              </li>
              <li class="list__item">
                <a href="https://justice.belgium.be/fr/" target="_blank"
                  >Service Public Fédéral JUSTICE</a
                >
              </li>
              <li class="list__item">
                <a
                  href="https://ec.europa.eu/taxation_customs/vies/#/vat-validation"
                  target="_blank"
                  >Vérification n° TVA</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="utils__left">
          <span class="utils__img"></span>
        </div>
      </div>
    </div>
  </div>

  <!-- Contact -->
  <div id="contact" class="contact">
    <div class="container">
      <div class="contact__blocks">
        <div class="contact__block">
          <h2 class="title title--small contact__title">Contact</h2>
          <ul class="contact__list">
            <li class="text text--small contact__item">
              <strong>Adresse : </strong
              ><a href="https://goo.gl/maps/LYqfQqddxF9g6t38A" target="_blank"
                >4ème Avenue 2, 6001 Charleroi</a
              >
            </li>
            <li class="text text--small contact__item">
              <strong>Email : </strong
              ><a href="mailto:info@fiduciaire-monaux.com"
                >info@fiduciaire-monaux.com</a
              >
            </li>
            <li class="text text--small contact__item">
              <strong>Télephone : </strong
              ><a href="tel:003271472550">071 47 25 50</a>
            </li>
            <li class="text text--small contact__item">
              <strong>Fax : </strong>071 47 35 68
            </li>
          </ul>
        </div>
        <!--
                 -->
        <div class="contact__block">
          <h2 class="title title--small contact__title">Heures d'ouverture</h2>
          <ul class="contact__list">
            <li class="text text--small contact__item">
              <strong>Lundi au Jeudi : </strong>08h30 - 12h30 | 13h00 - 17h00
            </li>
            <li class="text text--small contact__item">
              <strong>Vendredi : </strong>08h30 - 12h30 | 13h00 - 15h00
            </li>
          </ul>
        </div>
        <!--
                 -->
        <div class="contact__block">
          <h2 class="title title--small contact__title">Info</h2>
          <ul class="contact__list">
            <li class="text text--small contact__item">
              <strong>TVA : </strong>BE 0415 560 371
            </li>
            <li class="text text--small contact__item">
              <strong>N° d'agréation : </strong>50.404.634
            </li>
          </ul>
          <a
            href="https://www.itaa.be/fr/accueil/"
            target="_blank"
            class="contact__itaa"
            ><img src="/files/logo_itaa.png" alt="" class="img_full"
          /></a>
        </div>
      </div>
      <a
        href="https://www.linkedin.com/company/fiduciairemonauxsprl/about/"
        target="_blank"
        class="contact__linkedin"
        ><span class="contact__linkedin_icon"></span
      ></a>
    </div>
  </div>

  <!-- <Map></Map> -->
  <GoogleMap></GoogleMap>


  <Footer></Footer>
</template>

<script setup>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation";
import GoogleMap from "@/components/GoogleMap";

import {scrollTo} from "@/composables/scroll";


import $ from "jquery";
import skrollr from "skrollr";
import { watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

watch(() => route.params.section , (n, o)=> {
  
  setTimeout(() => scrollTo(n), 100);
})



/*  ==========================================================================
    Global
    ==========================================================================  */

var siteUrl = $("body").data("siteurl");
var baseUrl = $("body").data("baseurl");

/*  ==========================================================================
    Scroll to
    ==========================================================================  */

$(".js_scroll_to").on("click", function (e) {
  e.preventDefault();
  scrollto = $(this).data("scrollto");
  $("html, body").animate({ scrollTop: $(scrollto).offset().top - 150 }, 400);
});

/*  ==========================================================================
    Navigation
    ==========================================================================  */

/*  Shrink navigation on scroll
    ----------------------------------  */

function shrinkNav() {
  $(window).scroll(function () {
    if ($(window).scrollTop() > 20) {
      $("body").addClass("shrink_nav");
    } else {
      $("body").removeClass("shrink_nav");
    }
  });
}
shrinkNav();

$(window).on("resize", function () {
  shrinkNav();
});

/*  Toggle mobile nav
    ----------------------------------  */

$(".js_mobile_nav").click(function (e) {
  e.preventDefault();
  console.log('in');
  $body = $("body");
  if ($body.hasClass("nav_is_open")) {
    $body.removeClass("nav_is_open");
  } else {
    $body.addClass("nav_is_open");
  }
});


/*  ==========================================================================
    Skrollr
    ========================================================================== */

function initSkrollr() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    // mobile
  } else {
    if ($(window).width() > 1440) {
      skrollr.init({
        forceHeight: false,
        edgeStrategy: "set",
        easing: {
          WTF: Math.random,
          inverted: function (p) {
            return 1 - p;
          },
        },
      });
    } else {
      skrollr.init().destroy();
    }
  }
}

initSkrollr();

$(window).on("resize", function () {
  initSkrollr();
});
</script>

<style lang="scss">
@import "@/style/main";
@import "@/style/print.css";
</style>
